import React, { Component } from 'react';
import { HajjReadings } from "master-react-lib";
import Mosque from '../assets/imgs/mosque.png';


const carouselItems = [
    { image: Mosque, description: "The Kaaba is so central to the Islamic faith that, across the world.", link: "https://dev.zamzamhajj.com/imglib/HAJJ_Infographic.webp" },
    { image: Mosque, description: "A Fun-filled Occasion – Eid Poem", link: "#" },
    { image: Mosque, description: "Lessons Learned By A Muslim Traveler.", link: "#" },
    { image: Mosque, description: "Ramadan - The Most Honored Month", link: "#" },
    { image: Mosque, description: "The Kaaba is so central to the Islamic faith that, across the world.", link: "#" },
    { image: Mosque, description: "A Fun-filled Occasion – Eid Poem", link: "#" },
    { image: Mosque, description: "Lessons Learned By A Muslim Traveler.", link: "#" },
    { image: Mosque, description: "Ramadan - The Most Honored Month", link: "#" },
  ];

class HajjReading extends Component {

    render() {

        return (
            <div className="SectionContainer">
                <HajjReadings 
                    title="Hajj Reading"
                    carouselItems={carouselItems}
                />
            </div>
        );
    }
}


export default HajjReading;