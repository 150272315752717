import React from "react";
import "./App.css";
import HomePage from "./components/Pages/HomePage";
import Registration from "./components/Pages/Registration";
import UmrahForm from "./components/Pages/UmrahForm";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
} from "react-router-dom";
import CheckOut from "./components/CheckOut";
import ArticleDetails from "./components/ArticleDetails";
import ProcessedSuccessfully from "./components/ProcessedSuccessfully";
import SubscribeModal from "./components/SubscribeModal";
import ThanksSubscribeModal from "./components/ThanksSubscribeModal";
import TermsCondition from "./components/Pages/TermsCondition";
import UmrahPage from "./components/Pages/UmrahPage";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";

import "./assets/css/style.css";

function App() {
  return (
    <Router>
      <div className="Bg_Pattern"></div>
      <Switch>
        <Route exact path={"/"} component={() => <HomePage />} />
        <Route
          exact
          path={"/registration"}
          component={() => <Registration />}
        />
        <Route
          exact
          path={"/umrah-registration"}
          component={() => <UmrahForm />}
        />
        <Route exact path={"/check-out"} component={() => <CheckOut />} />
        <Route
          exact
          path={"/article-details"}
          component={() => <ArticleDetails />}
        />
        <Route
          exact
          path={"/processed-successfully"}
          component={() => <ProcessedSuccessfully />}
        />
        <Route
          exact
          path={"/subscribe-modal"}
          component={() => <SubscribeModal />}
        />
        <Route
          exact
          path={"/thanks-modal"}
          component={() => <ThanksSubscribeModal />}
        />
        <Route
          exact
          path={"/terms-condition"}
          component={() => <TermsCondition />}
        />
        <Route
          exact
          path={"/privacy-policy"}
          component={() => <PrivacyPolicy />}
        />

        <Route exact path={"/umrah"} component={() => <UmrahPage />} />
        <Route exact path={"/umrah-2024"} component={() => <UmrahPage />} />
      </Switch>
    </Router>
  );
}

export default App;
